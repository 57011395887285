<template>
  <router-link
    class="project-card col-md-3 mb-4"  
    :to="link"
  >
    <div
      class="card card-background align-items-start"
      :class="{
        'card-background-mask-dark': projectId > 0,
        'card-background-mask-primary': projectId < 1
      }"
    >
      <div
        class="d-block w-100"
      >
        <div 
          class="full-background cursor-pointer "
          
          :style="{
            background: 'url(' + image + ')'
          }"
        >
        </div>
        <div 
          class="card-body"
        >
          <div 
            v-if="!projectId"
            class="float-end" 
          >
            <i
              class="material-icons text-white" 
              style="font-size:60px;"
            >
              add_circle_outline
            </i>
          </div>

          <h5
            class="text-white mb-0"
          >
            {{ title }}
          </h5>
          <p
            class="text-white text-sm"
          >
            {{ subTitle ?? '&nbsp;' }}
          </p>
        </div>
      </div>
  </div>
</router-link>
</template>

<script>
import { parseDateTimeRange } from '@/helpers/parsers';
import { getDateString } from '@/helpers/datetime'

export default {
  name: 'ProjectCard',

  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: null
    },
    projectId: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      required: true,
    },
  },

  computed: {
    link() {
      return this.projectId
        ? {
          name: 'project-items',
          params: {
            projectId: this.projectId,
          },
        }
        : {
          name: 'new-project',
        };
    },
    dateCreateString() { return getDateString(new Date(this.publishDateTime)) },
    datePinnedString() {
      return this.pinnedDate instanceof String && this.pinnedDate !== ''
        ? getDateString(new Date(this.this.pinnedDate))
        : ''
    }
  },

  methods: {
    parseDateTimeRange,
  },
};
</script>

<style scoped lang="scss">
.news-card {
  &:nth-child(n + 5) {
    margin-top: var(--bs-gutter-x);
  }

  &__image {
    object-fit: cover;

    &-container {
      height: 200px;
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__reactions p {
    margin-bottom: 0;
  }
}</style>
